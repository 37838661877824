.linha-separadora {
  display: flex;
  justify-content: center;
}

.hor-centered {
    /* height: 100vh; */
    /* width: 100%; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .myname{
    /* height: 100vh; */
    /* width: 100%; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    font-weight: bold;
  }

  .small-picture {
    font-size: 0.9375rem;
  }